@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap'); */

body {
  font-family: "Ticketing Regular", sans-serif;
}

@font-face {
  font-family: "Ticketing Regular"; /* Use the font family name you found */
  src:url("C:/Users/codyj/AppData/Local/Microsoft/Windows/Fonts/Ticketing-jJB0.ttf") format('truetype');
}


